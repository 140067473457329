window.load_datatables_ajax = function() {
  $("[data-behaviour~=datatables-ajax]").each(function() {
    if (!$.fn.DataTable.isDataTable(this)) {
      let left, left1;
      const ordercol = (left = $(this).data('ordercol')) != null ? left : 0;
      const orderdir = (left1 = $(this).data('orderdir')) != null ? left1 : 'asc';
      return $(this).dataTable({
        // order: $(this).data('ordertable')
        order: [[ ordercol, orderdir]],
        lengthMenu:[[15, 25, 50, 100], [15, 25, 50, 100]],
        processing: true,
        serverSide: true,
        searchDelay: 600,
        ajax: $(this).data('source'),
        pagingType: 'full_numbers',
        columnDefs: [
          { orderable: false, targets: $(this).data('unsortable') },
          { visible: false, targets: $(this).data('hidden') }
        ]});
    }
  });
};
window.load_datatables = function() {
  return $("[data-behaviour~=datatables]").each(function() {
    let left, left1, left2, left3, left4, left5, pagethis;
    const ordercol = (left = $(this).data('ordercol')) != null ? left : 0;
    const orderdir = (left1 = $(this).data('orderdir')) != null ? left1 : 'asc';
    const search = (left2 = $(this).data('search')) != null ? left2 : true;
    const info = (left3 = $(this).data('info')) != null ? left3 : true;
    const length = (left4 = $(this).data('length')) != null ? left4 : true;
    const length_menu = (left5 = $(this).data('lengthmenu')) != null ? left5 : [[15, 25, 50, 100], [15, 25, 50, 100]];
    if ($(this).data('paging') === 'no') {
      pagethis = false;
    } else {
      pagethis = true;
    }
    if (!$.fn.DataTable.isDataTable(this)) {
      let table_buttons;
      if ($(this).data('print') === 'y') {
        table_buttons = [ 'print' ]; 
      } else {
        table_buttons = [ ]; 
      }
      const table = $(this).DataTable({
        order: [[ ordercol, orderdir]],
        lengthChange: length,
        "lengthMenu": length_menu,
        paging: pagethis,
        searching: search,
        info,
        buttons: table_buttons
      });
      // table.buttons().container().appendTo '#example_wrapper .col-md-6:eq(0)'
      if ($(this).data('print') === 'y') {
        // console.log 'add print button to table: ' + $(this).attr('id')
        return $('#' + $(this).attr('id') +  '_wrapper .col-md-6:eq(0)').html( table.buttons().container());
      }
    }
  });
};

