// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery-ui/ui/widget")
require("jquery-ui/ui/widgets/sortable")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"


import './bootstrap_custom.js'
import "../stylesheets/application"

// Add DataTables jQuery plugin
import $ from 'jquery'
global.$ = jQuery;
require('datatables.net-bs4')
import 'packs/datatables.js'
// import 'packs/fontawesome.js'

// CSS 
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  $('.toast').toast({ autohide: false })
  $('#toast').toast('show')
  // load_datatables_ajax() 
  // load_datatables() 
  if ($(".flash_alert").length > 0) {
    setTimeout(function(){
      // greet(randomGreeting);
      $(".flash_alert").each(function() {
        return $(this).slideUp(500);
      })
    }, 3000);
  }
  $("[data-behaviour~=sortable]").each(function() {
    console.log($(this).data('url'));
    return setSort($(this), $(this).data('url'));
  });
})


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

import "../trix-editor-overrides"



// document.addEventListener("turbolinks:load", () => {
//   $("#sortable_contents").sortable({
//     handle: '.handle',
//     update: function(e, ui) {
//       Rails.ajax({
//         url: $(this).data("url"),
//         type: "PATCH",
//         data: $(this).sortable('serialize'),
//       });
//     }
//   });
// })

const setSort = function(sortme, url) {
  $(sortme).sortable({
    placeholder: 'ui-state-highlight',
    update: function() {
      $.ajax({
        type: 'patch',
        data: $(sortme).sortable('serialize'),
        url: url
      });
    }
  });
};
